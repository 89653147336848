<script>
import { layoutComputed } from "@/state/helpers";

import Vertical from "./vertical";
import Horizontal from "./horizontal";
import TwoColumns from "./twocolumn";
import { ABILITY_TOKEN } from '@casl/vue';
import Swal from "sweetalert2";
import axios from "axios";

export default {
    inject: {
        $ability: { from: ABILITY_TOKEN }
    },
    components: {
        Vertical,
        Horizontal,
        TwoColumns
    },
    props: {
        module: {
            type: String
        }
    },
    data() {
        return {
            rules: this.$store.state.rules,
            hasPermission: true,
        };
    },
    methods: {
        logout() {
            localStorage.clear();
            this.$router.push({ name: 'login' });
        },
        checkPageAccess() {
            if (!this.$store.state.userRoles.includes(1)) {
                this.hasPermission = false;

                if (this.rules) {
                    this.rules.some(rule => {
                        if (this.$ability.can(rule.action, this.module)) {
                            this.hasPermission = true;
                        }

                        return this.hasPermission;
                    });
                }
            }
            
            if (!this.hasPermission) {
                Swal.fire({
                    title: "Access denied!",
                    text: "You do not have permission on this module.",
                    icon: "error",
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Ok",
                }).then(() => {
                    this.$router.go(-1);
                });
            }
        },
        async checkTokenExpired() {
            const userId = localStorage.getItem('userid');

            if (userId) {
                await axios.get(`${process.env.VUE_APP_AUTHDOMAIN}/api/v1/users/${userId}`)
                .then((response) => {
                    if (!response.data?.data) {
                        this.logout();
                    }
                }).catch(() => {
                    // console.log(error);
                    this.logout();
                });
            }
        },
    },
    computed: {
        ...layoutComputed
    },
    mounted() {
        this.checkTokenExpired();

        if (this.module) {
            this.checkPageAccess();
        }
    }
};
</script>

<template>
    <div>
        <Vertical v-if="layoutType === 'vertical' || layoutType === 'semibox'" :layout="layoutType">
            <slot v-if="hasPermission" />
        </Vertical>

        <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
            <slot v-if="hasPermission"/>
        </Horizontal>

        <TwoColumns v-if="layoutType === 'twocolumn'" :layout="layoutType">
            <slot v-if="hasPermission"/>
        </TwoColumns>
    </div>
</template>
